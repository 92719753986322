import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import * as S from "./styles";
import useCheckScreen from "../../../hooks/useCheckScreen";

const Customers = () => {
  const { homeData } = useSelector((state) => state.home);
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (homeData) {
      const clientes = homeData.highlights.filter((x) => x.type === "clientes");
      setCustomers(clientes);
    }
  }, [homeData]);

  if (customers.length === 0) return;

  return (
    <S.Container
      height={isMobile ? "200px" : "320px"}
      gap={isMobile ? "10px" : "20px"}
      padding={screen.paddingCustomers}
    >
      <S.Text>Conheça alguns dos nossos parceiros e clientes</S.Text>
      <S.ContainerCarousel>
        <Carousel
          responsive={S.Responsive}
          showDots={false}
          arrows={false}
          dotListClass="dot-list-class"
          partialVisible={true}
          autoPlay
          autoPlaySpeed={3000}
          infinite
          pauseOnHover
          draggable={true}
        >
          {customers.map((item, key) => {
            return (
              <S.Image
                key={key}
                src={item.image_url}
                height={screen.heightImageCustomers}
                width={screen.widthImageCustomers}
              ></S.Image>
            );
          })}
        </Carousel>
      </S.ContainerCarousel>
    </S.Container>
  );
};
export default Customers;
