import { getQuestionnaire } from "../../redux/quiz/actions";
import getCookies from "../cookies";

const event = "select_content";

export const createEventHomeDataLayer = async (page = "home") => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    //event: event,
    //pageCategory: page,
    user_id: await getCookies(),
  });
};

export const createEventDataLayer = async (contentType, contentId = "") => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...(event !== "") && {event: event},
    ...(contentType !== "") && {content_type: contentType},
    ...(contentId !== "") && {content_id: contentId},
  });

  // console.log({
  //   ...(event !== "") && {event: event},
  //   ...(contentType !== "") && {content_type: contentType},
  //   ...(contentId !== "") && {content_id: contentId},
  // });
};

export const sendFormAnswersToDataLayer = async (questionnaire) => {

  questionnaire.questions.forEach(question => {
    var questionTitle = question.question;
    var questionValue = null;

    if (question.value) {
      questionValue = question.value;
    } else {
      for(const option of question.options) {
        if (option.value === true) {
          questionValue = option.option;
        }
      };
    }

    if (questionValue) {

      //GA
      window.dataLayer.push({
        event: "answer",
        content_type: questionTitle,
        content_id: questionValue,
      });

      //FB
      if (typeof window.fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('trackCustom', 'answer', {
          question: questionTitle,
          answer: questionValue,
        });
      }

    }
  });

}
