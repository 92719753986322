import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMenu } from "../../../redux/menu/actions";
import * as S from "./styles";
import DropdownItem from "../DropdownItem";
import { menuMobile } from "./menu.js";
import useCheckScreen from "../../../hooks/useCheckScreen";

const Dropdown = () => {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);
  const [menuList, setMenuList] = useState([])
  const initialized = useRef(false)

  useEffect(() => {
    getMenuData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMenuData  = async  () => {
    if (!initialized.current) {
      initialized.current = true
      await dispatch(getMenu());
    }
  }

  useEffect(() => {
    setMenuList(menu);
  }, [menu]);

  const screen = useCheckScreen();
  const isMobile = screen.isMobile;

  if (menuList.length === 0) return;

  if (isMobile) {
    return (
      <S.BaseContainerMobile>
        {menuList.map((itemMenu, key) => {
          return <DropdownItem key={itemMenu.id} item={itemMenu} />;
        })}
        <DropdownItem key={99} item={menuMobile()} />;
      </S.BaseContainerMobile>
    );
  }
  return (
    <S.BaseContainerDesktop>
      {menuList.map((itemMenu, key) => {
        return <DropdownItem key={itemMenu.id} item={itemMenu} />;
      })}
    </S.BaseContainerDesktop>
  );
};

export default Dropdown;
