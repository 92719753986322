import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { useSelector } from "react-redux";
import useCheckScreen from "../../../hooks/useCheckScreen";
import { createEventDataLayer } from "../../../services/dataLayers";
import ProgressIndicator from "../../progressIndicator";

const SalesPerson = () => {
  const { homeData } = useSelector((state) => state.home);
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  // Fernando - ver com o Wagner
  const salesperson = "/images/salesperson.svg";
  const retangle = "/images/rectangle.svg";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (homeData) {
      const itens = homeData.highlights.filter(
        (x) => x.type === "representante",
      );
      setSales(itens);
    }
  }, [homeData]);

  if (sales.length === 0) return;

  const onClick = () => {
    setLoading(true);
    createEventDataLayer('Quero ser um representante');
    navigate('/product', {
      state: {
        product: "quero-ser-representante",
        pivot: {
          product_group_id: 3,
          product_id: 9
        }
      },
    });
  };

  return (
    <S.Container
      flexDirection={isMobile ? "column" : "row"}
      padding={screen.paddingSalesPerson}
      height={"auto"}
      justifyContent={isMobile ? "space-between" : "flex-start"}
    >
      <S.Wrapper
        flexDirection={isMobile ? "column" : "row"}
        gap={isMobile ? "40px" : "130px"}
      >
        <S.Wrapper>
          <S.Text fontName={isMobile ? "mobileHeader2" : "buttonDeskTop"}>
            Seja um representante Concreto
          </S.Text>
          <S.ContainerImage>
            <S.Image
              src={salesperson}
              width={isMobile ? "187px" : "390x"}
              height={isMobile ? "208px" : "438px"}
            ></S.Image>
          </S.ContainerImage>
        </S.Wrapper>
        <S.Wrapper
          gap={isMobile ? "24px" : "40px"}
          padding={isMobile ? "0px 0px 0px 0px" : "24px 16px 24px 0px"}
          alignSelf="center"
        >
          <S.Wrapper
            gap={isMobile ? "16px" : "28px"}
            padding={isMobile ? "0px 0px 0px 0px" : "24px 16px 24px 0px"}
          >
            {sales.map((item, key) => {
              return (
                <S.Wrapper
                  key={key}
                  flexDirection={"row"}
                  gap={isMobile ? "8px" : "16px"}
                >
                  <S.Image
                    src={retangle}
                    width={screen.retangleWidthHeight}
                    height={screen.retangleWidthHeight}
                  ></S.Image>
                  <S.Text fontName={isMobile ? "bodyText2" : "bodyText1"}>
                    {item.title}
                  </S.Text>
                </S.Wrapper>
              );
            })}
          </S.Wrapper>
          <S.Button onClick={() => onClick()}>
            <S.ContainerTextLoad>
              {loading && (
                <ProgressIndicator
                  width={"24px"}
                  height={"24px"}
                  color={"white"}
                  border={"4px"}
                  colorBorderTop={"beige"}
                ></ProgressIndicator>
              )}
              <S.Text fontName={isMobile ? "button2" : "button1"}>
                Quero ser um representante
              </S.Text>
            </S.ContainerTextLoad>
          </S.Button>
        </S.Wrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default SalesPerson;
