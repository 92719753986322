import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionnaire, setPivot } from "../../../redux/quiz/actions";
import ProgressIndicator from "../../progressIndicator";
import PropTypes from "prop-types";
import * as S from "./styles";
import useCheckScreen from "../../../hooks/useCheckScreen";
import { createEventDataLayer, sendFormAnswersToDataLayer } from "../../../services/dataLayers";
import Skeleton from "../../skeleton";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homeData } = useSelector((state) => state.home);
  const { questionnaire } = useSelector((state) => state.quiz);
  const [messageTitle, setMessageTitle] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [groups, setGroups] = useState([]);
  const [loadingGrupo0, setLoadingGrupo0] = useState(false);
  const [loadingGrupo1, setLoadingGrupo1] = useState(false);
  const [loadingGrupo2, setLoadingGrupo2] = useState(false);
  const [urlImage, setUrlImage] = useState('');

  const screen = useCheckScreen();
  const isMobile = screen.isMobile;

  useEffect(() => {
    if (homeData) {
      const banner = homeData.highlights.find((x) => x.type === "banner");
      const groups = homeData.groups;
      setUrlImage(banner.image_url);
      setMessageTitle(banner.title);
      setTextMessage(banner.description);
      setGroups(groups);
    }
  }, [homeData]);

  useEffect(() => {
    if (questionnaire) {
      setLoadingGrupo0(false);
      setLoadingGrupo1(false);
      setLoadingGrupo2(false);
      navigate("/question", {
        state: {
          questionnaire: questionnaire,
          stepInitial: 1,
          origin: 'menu',
          product: 'geral'
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire]);

  const onClick = async (item, index) => {
    setLoading(index);
    createEventDataLayer(item.name);
    await dispatch(setPivot(null));
    await dispatch(getQuestionnaire(item.quizzes[0].slug));
  };

  const setLoading = (index) => {
    switch (index) {
      case 0:
        setLoadingGrupo0(true);
        break;
      case 1:
        setLoadingGrupo1(true);
        break;
      default:
        setLoadingGrupo2(true);
    }
  };

  return (
    <S.Container>
      {groups.length === 0 && (
        <Skeleton width="100%" height="calc(100vh - 400px)" />
      )}
      {groups.length > 0 && (
        <S.Wrapper
          width={isMobile ? "100%" : "100%"}
          height={isMobile ? "500px" : "auto"}
          urlImg={urlImage}
          justifyContent="flex-end"
          padding={screen.paddingBannerHome}
        >
          <S.ContainerText height={isMobile ? "auto" : "auto"}>
            <S.ContainerText1
              height={isMobile ? "auto" : "auto"}
              width={isMobile ? "70%" : "60%"}
            >
              <S.Text
                fontName={isMobile ? "mobileHeader1" : "titleDesktop"}
                color="beige"
              >
                {messageTitle}
              </S.Text>
            </S.ContainerText1>
            <S.ContainerText2
              padding={isMobile ? "0px 0px 0px 0px" : "25px 0px 50px 0px"}
            >
              <S.Text
                fontName={isMobile ? "mobileBody" : "titleDesktop2"}
                color="white"
              >
                {textMessage}
              </S.Text>
            </S.ContainerText2>
          </S.ContainerText>
          <S.ContainerButton
            flexDirection={isMobile ? "column" : "row"}
            width={isMobile ? "100%" : "100%"}
            gap={isMobile ? "16px" : "32px"}
            padding={isMobile ? "0px 0px 0px 0px" : "48px 0px 0px 0px"}
          >
            <S.Button
              onClick={() => onClick(groups[0], 0)}
              height={isMobile ? "64px" : "104px"}
            >
              <S.ContainerTextLoad>
                {loadingGrupo0 && (
                  <ProgressIndicator
                    width={"24px"}
                    height={"24px"}
                    color={"beige"}
                    border={"4px"}
                    margin={isMobile ? "0px" : "8px"}
                    colorBorderTop={"white"}
                  ></ProgressIndicator>
                )}
                <S.TextButton fontName={isMobile ? "button" : "buttonDeskTop"}>
                  {groups[0].name}
                </S.TextButton>
              </S.ContainerTextLoad>
            </S.Button>
            <S.Button
              onClick={() => onClick(groups[1], 1)}
              height={isMobile ? "64px" : "104px"}
            >
              <S.ContainerTextLoad>
                {loadingGrupo1 && (
                  <ProgressIndicator
                    width={"24px"}
                    height={"24px"}
                    color={"beige"}
                    border={"4px"}
                    margin={isMobile ? "0px" : "8px"}
                    colorBorderTop={"white"}
                  ></ProgressIndicator>
                )}
                <S.TextButton fontName={isMobile ? "button" : "buttonDeskTop"}>
                  {groups[1].name}
                </S.TextButton>
              </S.ContainerTextLoad>
            </S.Button>
            <S.Button
              height={isMobile ? "64px" : "104px"}
              onClick={() => onClick(groups[2], 2)}
            >
              <S.ContainerTextLoad>
                {loadingGrupo2 && (
                  <ProgressIndicator
                    width={"24px"}
                    height={"24px"}
                    color={"beige"}
                    border={"4px"}
                    margin={isMobile ? "0px" : "8px"}
                    colorBorderTop={"white"}
                  ></ProgressIndicator>
                )}
                <S.TextButton fontName={isMobile ? "button" : "buttonDeskTop"}>
                  {groups[2].name}
                </S.TextButton>
              </S.ContainerTextLoad>
            </S.Button>
          </S.ContainerButton>
        </S.Wrapper>
      )}
    </S.Container>
  );
};


export default Banner;
