/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./styles";
import Container from "../../components/home/container";
import Header from "../../components/home/header";
import Banner from "../../components/home/banner";
import BestChoice from "../../components/home/bestChoice";
import Solutions from "../../components/home/solutions";
import CustomersSays from "../../components/home/customersSay";
import Customers from "../../components/home/customers";
import SalesPerson from "../../components/home/salesPerson";
import Footer from "../../components/home/footer";
import useCheckScreen from "../../hooks/useCheckScreen";
import { close } from "../../redux/quiz/actions";
import { getHome } from "../../redux/home/actions";
import { createEventHomeDataLayer } from "../../services/dataLayers";

const Home = () => {
  const [cookies, setCookie] = useCookies(["sessionId"]);
  const dispatch = useDispatch();
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  const [width, setWidth] = useState("1920px");
  const [height, setHeight] = useState("664px");
  const { cookiesData, homeData } = useSelector((state) => state.home);
  const initialized = useRef(false);

  dispatch(close());

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getHomeData();
      setDataLayer();
    }
  }, []);

  const getHomeData = async () => {
    await dispatch(getHome());
  };

  const setDataLayer = async () => {
    createEventHomeDataLayer('home');
  };

  useEffect(() => {
    if (cookiesData !== undefined && cookiesData !== "") {
      if (cookiesData.length > 0) {
        const cookiesBackend = cookiesData[0].toString().split(";");
        const cookiesName = cookiesBackend[0].toString().split("=");
        setCookie(cookiesName[0], cookiesName[1], { path: "/" });
        setDataLayer();
      }
    }
  }, [cookiesData]);

  useEffect(() => {
    if (isMobile) {
      setWidth("100%");
      setHeight("100%");
    } else {
      setWidth("100%");
      setHeight("664px");
    }
  }, [isMobile]);

  return (
    <S.BaseContainer>
      <Container
        width={width}
        height={height}
        padding="0px"
        gap="0px"
        opacity="0px"
        colorcontainer="white"
      >
        <Header showClose={false}></Header>
        <Banner></Banner>
        <BestChoice></BestChoice>
        <Solutions></Solutions>
        <CustomersSays></CustomersSays>
        <Customers></Customers>
        <SalesPerson></SalesPerson>
        <Footer></Footer>
      </Container>
    </S.BaseContainer>
  );
};

export default Home;
