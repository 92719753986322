import styled from "styled-components";

export const Chevron = styled.div`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  height: 0.25rem;
  width: 0.25rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
  transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
  transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
  transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.dark};
  z-index:99;
  padding: ${({ padding }) => padding || "16px 0px 0px 0px"};
  height: ${({ height }) => height || "auto"};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  width: auto;
  height: ${({ height }) => height || "auto"};
`;

export const ContentWrapperDeskTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  // max-width: 196px;
  padding: 0px;
  width: auto;
  height: ${({ height }) => height || "auto"};
`;

export const Content = styled.div`
  width: 328px;
  height: 16px;
  gap: 0px;
  opacity: 0px;
  padding: 16px 0px 32px 0px;
  margin: 0px;
  /* @media screen and (max-width: 1024px) {
    width: 108px;
  } */
`;

export const ContentDesktop = styled.div`
  height: 30px;
  gap: 0px;
  opacity: 0px;
  // width: fit-content;
  width: ${({ width }) => width || "fit-content"};
  padding: ${({ padding }) => padding || "8px 8px 8px 8px"};
  /* @media screen and (max-width: 1024px) {
    width: 108px;
  } */
`;


export const TextContainer = styled.div`
  padding: ${({ padding }) => padding};
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.beige};
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.white};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const TextSubMenu = styled.text`
  color: ${({ theme }) => theme.color.white};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Line = styled.hr`
  border-color: ${({ theme }) => theme.color.neutralGray};
  box-sizing: border-box;
  width: 100%;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
`;
