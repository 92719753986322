import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPhone } from "../../../redux/talkToUs/actions";
import * as S from "./styles";
import useCheckScreen from "../../../hooks/useCheckScreen";
import { callWhatsApp } from "../../../services/talkToUs";

const DropdownItem = ({ item }) => {
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.talkToUs);
  const navigate = useNavigate();
  const chevronDown = "/images/chevron-down.svg";
  const chevronUp = "/images/chevron-up.svg";
  const [height, setHeight] = useState(0);
  const [widthDesktop, setWidthDesktop] = useState("200px");
  const [chevron, setChevron] = useState(chevronDown);
  const [isExpanded, setIsExpanded] = useState();

  const contentHeight = isExpanded ? 800 : 0;

  useEffect(() => {
    onClickMouseLeave();
  }, []);

  const handleExpandToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
    setChevron(isExpanded ? chevronDown : chevronUp);
  }, [isExpanded]);

  const onClick = async (submenu, item) => {
    if (!isMobile) {
      onClickSubMenuDeskTop();
    }
    if (item.slug === "fale-conosco") {
      await dispatch(getPhone());
      callWhatsApp(isMobile, phone);
      return;
    }
    navigate("/product", {
      state: {
        product: submenu.slug,
        pivot: submenu.pivot,
      },
    });
  };

  const onClickSubMenuDeskTop = () => {
    if (item.products.length > 1) {
      setHeight(height === screen.heightMenu ? "0px" : screen.heightMenu);
      setWidthDesktop(height === screen.heightMenu ? screen.widthMinMenu : screen.widthMenu);
    }
  };

  const onClickMouseLeave = () => {
    setHeight("0px");
    setWidthDesktop(screen.widthMinMenu);
  };

  const getMenuMobile = () => {
    return (
      <S.Container padding="16px 0px 0px 0px">
        {item.products.length > 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={handleExpandToggle}
            >
              <S.Text fontName="mobileHeader2">{item.name}</S.Text>
              {item.products.length > 0 && <S.Image src={chevron} />}
            </S.Title>
            <S.ContentWrapper maxHeight={contentHeight} height={contentHeight}>
              {item.products.map((submenu, key) => {
                return (
                  <S.Content key={key} onClick={() => onClick(submenu, item)}>
                    <S.TextSubMenu fontName="mobileBody">
                      {submenu.name}
                    </S.TextSubMenu>
                  </S.Content>
                );
              })}
            </S.ContentWrapper>
            <S.Line></S.Line>
          </>
        )}
        {item.products.length <= 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={() => onClick(item, item)}
            >
              <S.Text fontName="mobileHeader2">{item.name}</S.Text>
              {item.products.length > 1 && <S.Image src={chevron} />}
            </S.Title>
            <S.Line></S.Line>
          </>
        )}
      </S.Container>
    );
  };

  const getMenuDeskTop = () => {
    return (
      <S.Container
        padding="4px 0px 0px 0px"
        height={height}
        onMouseLeave={() => onClickMouseLeave()}
      >
        {item.products.length > 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={() => onClickSubMenuDeskTop(item)}
            >
              <S.Text fontName="deskTopMenu1">{item.name}</S.Text>
            </S.Title>
            <S.ContentWrapperDeskTop maxHeight={height} height={height}>
              {item.products.map((submenu, key) => {
                return (
                  <S.ContentDesktop
                    padding={screen.paddingSubMenu}
                    key={key}
                    width={widthDesktop}
                    onClick={() => onClick(submenu, item)}
                  >
                    <S.TextSubMenu fontName="deskTopMenu2">
                      {submenu.name}
                    </S.TextSubMenu>
                  </S.ContentDesktop>
                );
              })}
            </S.ContentWrapperDeskTop>
          </>
        )}
        {item.products.length <= 1 && (
          <S.Title
            padding="0px 16px 0px 0px"
            width="auto"
            onClick={() => onClick(item, item)}
          >
            <S.Text fontName="deskTopMenu1">{item.name}</S.Text>
          </S.Title>
        )}
      </S.Container>
    );
  };

  return (
    <>
      {isMobile && getMenuMobile()}
      {!isMobile && getMenuDeskTop()}
    </>
  );
};

export default DropdownItem;
