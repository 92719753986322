import styled from "styled-components";

export const BaseContainerMobile = styled.div`
  padding: 2rem;
  width: 100%;
  height: 744px;
  background: ${({ theme }) => theme.color.dark};
`;

export const BaseContainerDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: auto;
  height: 100%;
  margin-left: 32px;
  background: ${({ theme }) => theme.color.dark};
`;
