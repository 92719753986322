/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as S from "./styles";
import useCheckScreen from "../../../hooks/useCheckScreen";
import DropDown from "../../menu/Dropdown";
import { clearQuestionnaire } from "../../../redux/quiz/actions";
import { getPhone } from "../../../redux/talkToUs/actions";
import { callWhatsApp } from "../../../services/talkToUs";
import { createEventDataLayer } from "../../../services/dataLayers";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.quiz);
  const { phone } = useSelector((state) => state.talkToUs);
  const { productData } = useSelector((state) => state.product);
  const [showMenu, setShowMenu] = useState(false);
  // Fernando - ver com o Wagner os ícones
  const logo = "/images/logo.svg";
  const menu = "/images/menu.svg";
  const close = "/images/close.svg";
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;

  useEffect(() => {
    if (isMobile && productData) {
      setShowMenu(false);
    }
    if (isMobile && !productData) {
      setShowMenu(false);
    }
  }, [productData]);

  const onClick = () => {
    setShowMenu(!showMenu);
    if (isOpen) {
      dispatch(clearQuestionnaire());
      navigate("/home");
    }
  };

  const onClickHome = () => {
    dispatch(clearQuestionnaire());
    navigate("/home");
  };

  const onClickTalkUs = async () => {
    createEventDataLayer("Fale Conosco");
    await dispatch(getPhone());
    callWhatsApp(isMobile, phone);
  };

  return (
    <S.Container>
      {isMobile && (
        <S.Wrapper height="56px">
          <S.Grid
            width="100%"
            height="56px"
            gap="0px"
            padding={screen.paddingHeaderHome}
          >
            <S.Image
              src={logo}
              width={screen.logoWidth}
              height={screen.logoHeight}
              onClick={() => onClickHome()}
              alt="Concreto - Fundo de Investimento"
            />

            <S.Image
              src={showMenu || isOpen ? close : menu}
              onClick={() => onClick()}
              width="20px"
              height="20px"
            />
          </S.Grid>
        </S.Wrapper>
      )}
      {isMobile && showMenu && <DropDown></DropDown>}
      {isMobile && !showMenu && <S.Grid></S.Grid>}
      {!isMobile && (
        <S.Wrapper
          height="86px"
          flexDirection="row"
          padding={screen.paddingHeaderHome}
        >
          <S.MenuContainer>
            <S.Image
              src={logo}
              width={screen.logoWidth}
              height={screen.logoHeight}
              onClick={() => onClickHome()}
              alt="Concreto - Fundo de Investimento"
            />
            {!isOpen && <DropDown></DropDown>}
          </S.MenuContainer>
          <S.Grid padding="6px 0 0 0">
            <S.Button onClick={() => onClickTalkUs()} padding={screen.paddingButtonTalkUs}>
              <S.Text fontName="label2">Fale conosco</S.Text>
            </S.Button>
          </S.Grid>
        </S.Wrapper>
      )}
    </S.Container>
  );
};

export default Header;
