import React from "react";
import * as S from "./styles";
import useCheckScreen from "../../../hooks/useCheckScreen";
import { createEventDataLayer, sendFormAnswersToDataLayer } from "../../../services/dataLayers";

const Footer = () => {
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  // Fernando - ver com o Wagner os ícones
  const logo = "/images/logo.svg";
  const linkedin = "/images/linkedin.svg";
  const rede1 = "/images/rede1.svg";
  const onClickCall = (item) => {
    createEventDataLayer("Rede Social", item);
  };
  const getMobile = () => {
    return (
      <S.Container
        padding={screen.paddingFooter}
        height="368px"
        flexDirection="column"
        justifyContent="flex-start"
        color="dark90"
      >
        <S.Image
          src={logo}
          width="141px"
          height="24px"
          alt="Concreto - Fundo de Investimento"
        />
        <S.ContainerInformation>
          <S.Text fontName="bodyText">(31) 2117-9072</S.Text>
          <S.Text fontName="bodyText">contato@concreto.com.br</S.Text>
        </S.ContainerInformation>
        <S.ContainerInformation>
          <S.Text fontName="bodyTextNegrito">Acompanhe</S.Text>
          <S.ContainerSocialMidia>
            <S.HeadLink target="_blank" href={"https://www.instagram.com/concreto.fidc"} onClick={() => onClickCall("instagram")}>
              <S.Image src={rede1} width="24px" height="24px" />
            </S.HeadLink>
            <S.HeadLink target="_blank" href={"https://www.linkedin.com/company/concreto-fidc"} onClick={() => onClickCall("linkedin")}>
              <S.Image src={linkedin} width="24px" height="24px" />
            </S.HeadLink>

          </S.ContainerSocialMidia>
        </S.ContainerInformation>
        <S.ContainerInformation>
          <S.HeadLink href={"/termo"}>
            <S.Text fontName="bodyTextNegrito">
              Termos de uso e privacidade&nbsp;&nbsp;
            </S.Text>
          </S.HeadLink>
          <S.Text fontName="bodyTextNegrito2">© 2024 Concreto FIDC</S.Text>
          <S.Text fontName="bodyText2">
            Av. Brig. Faria Lima, 3900 - cj 601, 6 andar - Itaim Bibi, São
            Paulo, Brasil
          </S.Text>
        </S.ContainerInformation>
      </S.Container>
    );
  };
  const getDesktop = () => {
    return (
      <S.Container
        padding={screen.paddingFooter}
        height="204px"
        flexDirection="column"
        justifyContent="flex-start"
        gap="32px"
        color="dark90"
      >
        <S.Wrapper
          flexDirection="row"
          gap="0px"
          justifyContent="space-between"
          color="dark90"
        >
          <S.Image
            src={logo}
            width="141px"
            height="24px"
            alt="Concreto - Fundo de Investimento"
          />
          <S.Wrapper color="dark90">
            <S.Text fontName="bodyText">(31) 2117-9072</S.Text>
            <S.Text fontName="bodyText">contato@concreto.com.br</S.Text>
          </S.Wrapper>
          <S.Wrapper color="dark90">
            <S.Text fontName="bodyTextNegrito">Acompanhe</S.Text>
            <S.ContainerSocialMidia>
              <S.HeadLink target="_blank" href={"https://www.instagram.com/concreto.fidc"} onClick={() => onClickCall("instagram")}>
                <S.Image src={rede1} width="24px" height="24px" />
              </S.HeadLink>
              <S.HeadLink target="_blank" href={"https://www.linkedin.com/company/concreto-fidc"} onClick={() => onClickCall("linkedin")}>
                <S.Image src={linkedin} width="24px" height="24px" />
              </S.HeadLink>
            </S.ContainerSocialMidia>
          </S.Wrapper>
        </S.Wrapper>
        <S.Wrapper justifyContent="space-between" gap="16px" color="dark90">
          <S.Line></S.Line>
          <S.Wrapper
            flexDirection="row"
            justifyContent="space-between"
            padding="16px 0px 0px 0px"
            color="dark90"
          >
            <S.HeadLink href={"/termo"}>
              <S.Text fontName="bodyTextNegrito">
                Termos de uso e privacidade&nbsp;&nbsp;
              </S.Text>
              <S.Text fontName="bodyTextNegrito2">© 2024 Concreto FIDC</S.Text>
            </S.HeadLink>
          </S.Wrapper>
          <S.Text fontName="bodyText2">
            Av. Brig. Faria Lima, 3900 - cj 601, 6 andar - Itaim Bibi, São
            Paulo, Brasil
          </S.Text>
        </S.Wrapper>
      </S.Container>
    );
  };
  return isMobile ? getMobile() : getDesktop();
};

export default Footer;
