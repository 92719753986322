import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 32px 144px 16px 144px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  top:0px;
  width: 100%;
  height: ${({ height }) => height || "space-between"};
  gap: 0px;
  opacity: 0px;
  background: ${({ theme }) => theme.color.dark};
  padding: ${({ padding }) => padding || "16px 0px 0px 0px"};
  /* @media screen and (${({ theme }) => theme.breakpoints.minXS}) {
    width: 1632px;
    height: 88px;
    padding: 16px 24px 16px 24px;
    border-radius: 4px 0px 0px 0px;
  } */
  align-items: center;
`;

export const Image = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  cursor: pointer;
`;

export const Grid = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  gap: ${({ gap }) => gap || "0"};
  margin: ${({ margin }) => margin || "0"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  opacity: ${({ opacity }) => opacity || "0px"};
  padding: ${({ padding }) => padding || "0"};
`;

export const Column = styled.div`
  margin: ${({ margin }) => margin || "0"};
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 798px; */
  height: 35.46px;
  gap: 32px;
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.white};
  width: Hug (80px) px;
  height: Hug (21px) px;
  gap: 10px;
  opacity: 0px;
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.beige};
  border: 0px;
  width: auto;
  height: 40px;
  padding: ${({ padding }) => padding || "0"};
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
`;

