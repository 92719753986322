import axios from "axios";
import getCookies from "../cookies";

const ConfigAxios = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] =
        "Bearer " + process.env.REACT_APP_BFF_TOKEN;
      config.headers["Content-Type"] = "application/json";
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Access-Control-Allow-Methods"] =
        "GET, POST, PUT, PATCH, DELETE, OPTIONS";
      config.headers["Access-Control-Allow-Headers"] =
        "Origin, Accept, Content-Type, Authorization, X-Requested-With";
      let headerCookie = getCookies();
      if (headerCookie !== "" && headerCookie !== undefined) {
        config.headers["user"] = headerCookie;
      } else {
        config.headers["user"] = "";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
};


export default ConfigAxios;
