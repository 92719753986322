import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: row;
  top: ${({ top }) => top || "0px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "600px"};
  gap: ${({ gap }) => gap || "24px"};
  opacity: 0px;
  background-image: url(${({ urlImg }) => urlImg});
  padding: ${({ padding }) => padding || "16px 16px 16px 16px"};
  background-position: center;
  margin: ${({ margin }) => margin || "0 0 100px"};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* @media screen and (${({ theme }) => theme.breakpoints.minXS}) {
    width: 1632px;
    height: 88px;
    padding: 16px 24px 16px 24px;
    border-radius: 4px 0px 0px 0px;
    background-size: 1632px;
  } */
`;

export const ContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ padding }) => padding || "16px 0px 0px 0px"};
  margin: 0px;
  gap:16px;
  width: 100%;
  height: ${({ height }) => height || "159px"};
`;

export const ContainerText1 = styled.div`
  display: flex;
  flex-wrap: wrap
  flex-direction: column;
  margin: 0px;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "55%"};
  padding: ${({ padding }) => padding || "0px 0px 0px 0px"};
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);

`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const ContainerText2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || "0px 0px 0px 0px"};
  margin: 0px;
  width: ${({ width }) => width || "100%"};
  height: 45%;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};
  border: 0px;
  width: 91%;
  height: ${({ height }) => height || "64px"};
  gap: 10px;
  border-radius: 8px 8px 8px 8px;
  opacity: 0px;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  gap: ${({ gap }) => gap || "16px"};
  width: ${({ width }) => width || "110%"};
  height: 176px;
  padding: ${({ padding }) => padding || "0px 0px 0px 0px"};
`;

export const TextButton = styled.text`
  color: ${({ theme }) => theme.color.dark};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
`;

export const FormContainter = styled.div`

  margin-top: ${({ marginTop }) => marginTop || "200px"};
  margin-left: ${({ marginLeft }) => marginLeft || "1050px"};
  margin-right: ${({ marginRight }) => marginRight || "0"};
`;
