import styled from 'styled-components';

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: Hug (3,450.75px)px;
  padding: 0;
  margin: 0;
  gap: ${({ gap }) => gap || "16px"};
  opacity: 0px;
  z-index:-1;
`;

export const Wrapper = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
background: ${({ theme }) => theme.color.white};
padding:  ${({ padding }) => padding || "16px 16px 16px 16px"};
gap:  ${({ gap }) => gap || "16px"};

p {
  margin: 0;
  text-indent: 3ch;
  text-align: justify;
  text-justify: inter-word;
}

h1 {
  padding: 16px;
}

li {
  margin-left: 16px;
}

`;


export const Text = styled.text`
color: ${({ theme, color }) => theme.color[color]};
font: ${({ theme, fontName }) => theme.typography[fontName]};
text-align: left;
`;
