import axios from "axios";
import types from "../types";

export const open = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_OPEN,
    });
  };
};

export const close = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CLOSE,
    });
  };
};

export const setStep = (step) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_STEP,
      payload: step,
    });
  };
};

export const setNumberStep = (numberStep) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_NUMBERSTEP,
      payload: numberStep,
    });
  };
};

export const setHasError = (hasError) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_HASERROR,
      payload: hasError,
    });
  };
};

export const clearQuestionnaire = () => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_QUESTIONNAIRE,
      payload: null,
    });
  };
};

export const setQuestionnaire = (questionnaire) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_QUESTIONNAIRE,
      payload: questionnaire,
    });
  };
};

export const sendQuestionnaire = (questionnaire) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_BFF}quiz`;
    try {
      axios
        .post(url, questionnaire, { timeout: 300000, withCredentials: false })
        .then((response) => {})
        .catch((error) => {});
    } catch (error) {}
  };
};

export const getQuestionnaire = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_CLEAR_QUESTIONNAIRE });
    const url = `${process.env.REACT_APP_BFF}quiz?id=${id}`;
    try {
      axios
        .get(url, { timeout: 300000, withCredentials: false })
        .then((response) => {
          dispatch({
            type: types.SET_QUESTIONNAIRE,
            payload: response.data.data.quiz,
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };
};


export const sendOption = (parameter) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_BFF}quiz/option`;
    try {
      axios
        .post(url, parameter, { timeout: 300000, withCredentials: false })
        .then((response) => {
          dispatch({
            type: types.SET_OPTION,
            payload: response.data.data.response,
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };
};

export const setNextQuestionId = (nextQuestionId) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_NEXTQUESTIONID,
      payload: nextQuestionId,
    });
  };
};

export const setQuestionIdToNextStep = (questionIdToNextStep) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_QUESTIONAIDTONEXTSTEP,
      payload: questionIdToNextStep,
    });
  };
};

export const setOriginOptionId = (originOptionId) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_ORIGINOPTIONID,
      payload: originOptionId,
    });
  };
};

export const setPivot = (pivot) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_PIVOT,
      payload: pivot,
    });
  };
};

export const setQuestionDraw = (questions) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_QUESTIONSDRAW,
      payload: questions,
    });
  };
}


