export const typographyBreakpoint1 = "@media(max-width: 767px)";
export const typographyBreakpoint2 = "@media(max-width: 1264px)";
export const typographyBreakpoint3 = "@media(max-width: 836px)";
export const typographyBreakpoint4 = "@media(max-width: 1770px)";

const typography = {
  title1: {
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "21.09px",
    fontWeight: 600,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
    },
  },
  titleDesktop: {
    fontFamily: "Power Grotesk",
    fontSize: "66px",
    lineHeight: "67.2px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "33px",
      lineHeight: "33px",
    },
    [typographyBreakpoint2]: {
      fontSize: "53px",
      lineHeight: "53px",
    },
    [typographyBreakpoint3]: {
      fontSize: "42px",
      lineHeight: "42px",
    },
  },
  titleDesktop2: {
    fontFamily: "Roboto",
    fontSize: "24px",
    lineHeight: "28.13px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
    [typographyBreakpoint2]: {
      fontSize: "19px",
      lineHeight: "22´x",
    },
    [typographyBreakpoint3]: {
      fontSize: "15px",
      lineHeight: "18px",
    },
  },
  mobileHeader1: {
    fontFamily: "Power Grotesk",
    fontSize: "24px",
    lineHeight: "28.8px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileHeader2: {
    fontFamily: "Power Grotesk",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [typographyBreakpoint2]: {
      fontSize: "16px",
      lineHeight: "19.2px",
    },
    [typographyBreakpoint3]: {
      fontSize: "13px",
      lineHeight: "15.36px",
    },
  },
  deskTopMenu1: {
    fontFamily: "Power Grotesk",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "11px",
      lineHeight: "13px",
    },
    [typographyBreakpoint2]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    [typographyBreakpoint3]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
    [typographyBreakpoint4]: {
      fontSize: "18px",
      lineHeight: "21px",
    },
  },

  deskTopMenu2: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.41px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "9px",
      lineHeight: "10px",
    },
    [typographyBreakpoint2]: {
      fontSize: "11px",
      lineHeight: "13px",
    },
    [typographyBreakpoint3]: {
      fontSize: "10px",
      lineHeight: "11px",
    },
    [typographyBreakpoint4]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },

  mobileBody: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.41px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileBody2: {
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "11.72px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button: {
    fontFamily: "Power Grotesk",
    fontSize: "16px",
    lineHeight: "19.2px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button1: {
    fontFamily: "Power Grotesk",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button2: {
    fontFamily: "Power Grotesk",
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 400,
    letterSpacing: "0.5px",
    fontStyle: "none",
  },
  label: {
    fontFamily: "Power Grotesk",
    fontSize: "12px",
    lineHeight: "14.4px",
    fontWeight: 400,
    fontStyle: "none",
  },
  label2: {
    fontFamily: "Power Grotesk",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "9px",
      lineHeight: "11px",
    },
    [typographyBreakpoint2]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
    [typographyBreakpoint3]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  buttonDeskTop: {
    fontFamily: "Power Grotesk",
    fontSize: "32px",
    lineHeight: "38.4px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [typographyBreakpoint2]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
    [typographyBreakpoint3]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  bodyText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.4px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyText1: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "23.44px",
    fontWeight: 400,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "11px",
      lineHeight: "12px",
    },
    [typographyBreakpoint2]: {
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    [typographyBreakpoint3]: {
      fontSize: "13px",
      lineHeight: "15px",
    },
  },
  bodyText1Bold: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "23.44px",
    fontWeight: 700,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "11px",
      lineHeight: "12px",
    },
    [typographyBreakpoint2]: {
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    [typographyBreakpoint3]: {
      fontSize: "13px",
      lineHeight: "15px",
    },
  },
  bodyText2Bold: {
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "21.09px",
    fontWeight: 700,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "9px",
      lineHeight: "11px",
    },
    [typographyBreakpoint2]: {
      fontSize: "14px",
      lineHeight: "16.87px",
    },
    [typographyBreakpoint3]: {
      fontSize: "12px",
      lineHeight: "13.5px",
    },
  },
  bodyText2: {
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "14.06px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyTextNegrito: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.4px",
    fontWeight: 700,
    fontStyle: "none",
  },
  bodyTextNegrito2: {
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "14.52px",
    fontWeight: 600,
    fontStyle: "none",
  },
  button3: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "18.75px",
    fontStyle: "none",
  },
};


export default typography;
