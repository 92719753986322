import { useEffect, useState } from "react";

const useCheckScreen = () => {
  const [width, setWidth] = useState(window.screen.width);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const valuePaddng1 = 64;
  const percentual = width > 1920 ? 1 : width / 1920;
  const paddingSubMenu = width > 1264 ? 8 : 2;

  if (width <= 768) {
    return {
      isMobile: true,
      paddingBannerHome: "16px 0px 16px 0px",
      paddingHeaderHome: "0px 16px 0 16px",
      paddingBestChoice: "40px 26px 16px 16px",
      paddingCustomers: "16px 16px 16px 16px",
      paddingCustomersSay: "16px 16px 16px 16px",
      paddingSolutions: "50px 16px 16px 16px",
      paddingFooter: "24px 16px 24px 16px",
      paddingSalesPerson: "24px 16px 24px 16px",
      paddingBannerProduct: "16px 16px 16px 16px",
      paddingPageProduct: "40px 20px 40px 20px",
      paddingPageTermo: "16px 16px 16px 16px",
      paddingButtonTalkUs: "8px 32px 8px 32px",
      paddingPageQuestionnaire: "0px 0px 16px 0px",
      logoWidth: "141px",
      logoHeight: "24px",
      widthMenu: "328px",
      widthMinMenu: "200px",
      heightMenu: "188px",
      paddingSubMenu: "4px 4px 4px 4px",
      widthForm: "100%",
      heightImageSolutions: "128px",
      heightImageCustomers: "50px",
      widthImageCustomers: "100px",
      retangleWidthHeight: "16px",
    };
  }
  if (width <= 1920) {
    return {
      isMobile: false,
      paddingBannerHome:
        "260px " +
        valuePaddng1 * percentual +
        "px 60px " +
        valuePaddng1 * percentual +
        "px",
      paddingHeaderHome: "16px 144px 16px " + 74 * percentual + "px",
      paddingBestChoice: "70px 64px 0px " + valuePaddng1 * percentual + "px",
      paddingCustomers: "24px 64px 24px " + valuePaddng1 * percentual + "px",
      paddingCustomersSay: "64px 64px 0px " + valuePaddng1 * percentual + "px",
      paddingSolutions: "60px 64px 24px " + valuePaddng1 * percentual + "px",
      paddingFooter: "16px 64px 0px " + valuePaddng1 * percentual + "px",
      paddingSalesPerson: "44px 64px 40px " + valuePaddng1 * percentual + "px",
      paddingBannerProduct:
        "128px 128px 128px " + valuePaddng1 * percentual + "px",
      paddingPageProduct: "0px 64px 64px " + valuePaddng1 * percentual + "px",
      paddingPageTermo: "128px 64px 64px " + valuePaddng1 * percentual + "px",
      paddingButtonTalkUs:
        "8px " + 64 * percentual + "px 8px " + 64 * percentual + "px",
      logoWidth: 208 * percentual < 141 ? "141px" : 208 * percentual + "px",
      logoHeight: 36 * percentual < 24 ? "24px" : 36 * percentual + "px",
      widthMenu: 328 * percentual + "px",
      widthMinMenu: (200 * percentual < 128 ? 128 : 200 * percentual) + "px",
      heightMenu: (width < 1264 ? 148 : 188) + "px",
      paddingSubMenu:
        paddingSubMenu +
        "px " +
        paddingSubMenu +
        "px " +
        paddingSubMenu +
        "px " +
        paddingSubMenu +
        "px",
      widthForm: width < 945 ? "428px" : "528px",
      heightImageSolutions: 250 * percentual + "px",
      heightImageCustomers: 116 * percentual + "px",
      widthImageCustomers: 312 * percentual + "px",
      retangleWidthHeight: (23 * percentual < 16 ? 16 : 23 * percentual) + "px",
      paddingPageQuestionnaire: 64 * percentual + "px",
    };
  }
  return {
    isMobile: false,
    paddingBannerHome:
      "260px " + valuePaddng1 + "px 60px " + valuePaddng1 + "px",
    paddingHeaderHome: "16px 144px 16px 74px",
    paddingBestChoice: "70px 64px 0px " + valuePaddng1 + "px",
    paddingCustomers: "24px 64px 24px " + valuePaddng1 + "px",
    paddingCustomersSay: "64px 64px 0px " + valuePaddng1 + "px",
    paddingSolutions: "60px 64px 24px " + valuePaddng1 + "px",
    paddingFooter: "16px 64px 0px " + valuePaddng1 + "px",
    paddingSalesPerson: "44px 64px 40px " + valuePaddng1 + "px",
    paddingBannerProduct: "128px 64px 128px " + valuePaddng1 + "px",
    paddingPageProduct: "0px 64px 64px " + valuePaddng1 + "px",
    paddingPageTermo: "128px 64px 64px " + valuePaddng1 + "px",
    paddingButtonTalkUs: "8px 32px 8px 32px",
    logoWidth: "208px",
    logoHeight: "36px",
    widthMenu: "328px",
    widthMinMenu: "200px",
    paddingSubMenu: "8px 8px 8px 8px",
    heightMenu: "188px",
    widthForm: "528px",
    heightImageSolutions: "250px",
    heightImageCustomers: "116px",
    widthImageCustomers: "312px",
    retangleWidthHeight: "24px",
    paddingPageQuestionnaire: "64px",
  };
};

export default useCheckScreen;
